import React from 'react';
import {
  Container,
  Row,
} from 'reactstrap';
import { Layout } from '../components';

const WelcomePage = () => (
  <Layout>
    <Row className="welcome-row">
      <Container>
        <h1 className="welcome-title">
          Thanks for joining the waiting list
        </h1>
        <h2 className="welcome-subtitle">You will receive:</h2>
        <ul className="welcome-bullets">
          <li> Early access to our app as soon as it is ready.</li>
          <li> A monthly newsletter highlighting tools and tricks, personal budgeting stories and
            motivational hacks, as well as the progress of our app
          </li>
        </ul>
      </Container>
      <div className="welcome-img">
        <img src="/img/thankyou.svg" alt="people celebrating" />
      </div>
    </Row>
  </Layout>
);

export default WelcomePage;
